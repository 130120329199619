
<template>
  <div id="financial-agreement">
    <h1 v-if="$route.name !== 'Financial-Agreement'">FIL流动挖矿风险揭示书</h1>
    <p>
      本风险揭示书旨在披露投资者依据对应的理财合同，向HM
      pool（以下简称“公司”）购买的FIL流动挖矿可能产生的交易风险和损失。
    </p>
    <p>
      投资者购买流动挖矿前已仔细阅读并完全理解对应的理财合同中的所有条款及相关法律规定，确认已经从公司获得令其满意的信息披露，并确认具有承担相关风险的能力。投资者已经完全理解流动挖矿的性质、风险及可能的损失。投资者根据自身理财目标、投资经验、财务状况、风险承受能力以及其他相关情况慎重考虑购买流动挖矿。本风险揭示书列举的具体风险并不能穷尽流动挖矿的所有风险。下列列举的具体风险只是作为例证而不表明公司对未来市场趋势的观点。
    </p>
    <p>
      <b>第一条</b>
      一般风险：流动挖矿涉及显著的风险，仅适合具备对风险进行衡量所必需的财务及经营方面的知识和经验的投资者。投资于本产品可能承担巨大风险。投资者应在经过咨询其专业顾问、考虑其自身状况、并认定投资产品为合适的投资后，才可投资于本投资产品。
    </p>
    <p>
      <b>第二条</b>
      产品不成立风险：产品募集期内，如出现市场剧烈波动、相关法规政策变化或其他HM
      pool合理认为可能影响FIL流动挖矿正常运作的情况，公司有权宣布FIL流动挖矿不成立，投资者将无法按约定购买FIL流动挖矿，因此产生的后果由投资者承担。前述约定不免除因公司过错依法应由公司承担的责任。
    </p>
    <p>
      <b>第三条</b>
      期限风险：如FIL理财合同约定只有公司有权行使对产品期限的权利（包括但不限于提前终止、展期），一旦公司选择了行使FIL理财合同中所订明的权利时，则投资者将须按照FIL理财合同约定执行。
    </p>
    <p>
      <b>第四条</b>
      再投资风险：如果公司行使提前终止或展期的权利，产品实际投资期限可能不同于FIL理财合同约定的期限。在投资到期日后进行再投资时，投资者可能会面临再投资的收益率低于预期收益率的状况。
    </p>
    <p>
      <b>第五条</b>
      利率风险：由于市场的波动性，投资者投资于FIL流动挖矿将面临一定的利率风险。
    </p>
    <p>
      <b>第六条</b>
      市场风险：FIL流动挖矿本金和FIL理财收益来源于FIL流动挖矿对应投资组合的运作和回报，因市场行情、FIL价格等因素可能发生变化，导致投资品种的市场价格发生波动，由此可能导致FIL价格出现涨跌情况。
    </p>
    <p>
      <b>第七条</b>
      流动性风险：FIL流动挖矿并非随时可流动的产品。除非理财合同另有约定，如投资者不享有提前终止权，投资者应持有至投资到期日。如果投资者产生流动性需求，可能面临产品不能随时变现、持有期与资金需求日不匹配的风险。
    </p>
    <p>
      <b>第八条</b>
      信息传递风险：FIL流动挖矿不提供纸质账单。投资者需要通过登录公司门户网站或到公司现场咨询等方式，了解产品相关信息公告。投资者应根据产品说明书所载明的公告方式及时查询FIL流动挖矿的相关信息。如果投资者未及时查询，或由于不可抗力及/或意外事件的影响使得投资者无法及时了解FIL流动挖矿信息，并影响投资者的投资决策，由此产生的责任和风险由投资者自行承担。双方在补充协议/补充条款中另有约定的除外。前述约定不免除因公司过错依法应由公司承担的责任。
    </p>
    <p>
      <b>第九条</b>
      不可抗力及意外事件风险：由于不可抗力及/或国家政策变化、IT系统故障、通讯系统故障、电力系统故障、金融危机、投资市场停止交易等非公司所能控制的原因（“意外事件”），可能对FIL流动挖矿的产品成立、投资运作、资金返还、信息披露、公告通知造成影响，可能导致产品收益降低乃至FIL流动挖矿遭受损失。对于由不可抗力及意外事件风险导致的损失，投资者须自行承担，公司对此不承担责任，双方在补充协议/补充条款中另有约定的除外。前述约定不免除因公司过错依法应由公司承担的责任。因不可抗力及/或意外事件导致公司无法继续履行理财合同的，公司有权提前终止FIL流动挖矿，并将发生不可抗力及/或意外事件后剩余的投资者FIL流动挖矿数量划付至投资者清算账户。
    </p>
    <p><b>理财非存款、产品有风险、投资需谨慎。</b></p>
    <p>
      产品过往业绩不代表其未来表现，不构成新发流动挖矿业绩表现的保证。请投资者在购买流动挖矿前仔细阅读理财合同。
    </p>
    <p>
      如为保证收益型FIL流动挖矿，公司按约定条件向客户承诺支付固定FIL理财收益或最低FIL理财收益；如为保本浮动收益型FIL流动挖矿，公司按约定条件向客户保证本金支付，不保证FIL理财收益；如为非保本浮动收益型FIL流动挖矿，公司根据约定条件和实际投资收益向客户支付收益，不保证FIL理财本金和收益。如FIL流动挖矿为净值型产品，投资者所获得赎回金额或清算分配金额还将受限于产品资产单位净值。投资者应充分认识投资风险，谨慎投资。在购买FIL流动挖矿后，请投资者随时关注FIL流动挖矿的信息披露情况，及时获取相关信息。
    </p>
    <p>如影响您风险承受能力的因素发生变化，请及时完成风险承受能力评估。</p>
    <p>
      <b
        >投资者确认：投资者签署FIL理财合同的即表示投资者已经阅读上述风险提示，充分了解并清楚知晓本产品的风险，愿意承担相关风险。</b
      >
    </p>
  </div>
</template>

<script>
export default {
  name: "financial-agreement",
};
</script>

<!-- 添加"scoped"属性，以限制CSS仅对该组件 -->
<style lang="less" scoped>
#financial-agreement {
  > h1 {
    //: 1.5em;
    text-align: center;
    position: relative;
    color: white;

    &::before {
      content: "《";
    }

    &::after {
      content: "》";
    }
  }

  > p {
    // text-indent: 2em;
    line-height: 1.8em;

    &::before {
      content: "";
      display: inline-block;
      width: 2em;
      height: 100%;
      background-color: #000;
    }
  }
}
</style>
