<template>
  <div id="Money-Manage-Details">
    <!-- <img :src="`public/images/logo/logo-2.png`" alt="" /> -->
    <div class="purchase-form">
      <a-form-model
        ref="MoneyMangeForm"
        :model="MoneyMangeForm.item"
        :rules="MoneyMangeForm.rules"
        v-bind="{
          labelCol: { span: 0 },
          wrapperCol: { span: 24 },
        }"
      >
        <template>
          <a-form-model-item>
            <div class="form-item">
              <div>基金名称</div>
              <div>
                <template>{{ MoneyMangeInfo.financial_name }}</template>
              </div>
            </div>
          </a-form-model-item>

          <a-form-model-item>
            <div class="form-item">
              <div>总量额度</div>
              <div>
                <template>{{ MoneyMangeInfo.total_quota }}</template>
              </div>
            </div>
          </a-form-model-item>

          <a-form-model-item>
            <div class="form-item">
              <div>最小购买</div>
              <div>
                <template>{{ MoneyMangeInfo.min_purchase }}</template>
              </div>
            </div>
          </a-form-model-item>

          <a-form-model-item>
            <div class="form-item">
              <div>最大购买</div>
              <div>
                <template>{{ MoneyMangeInfo.max_purchase }}</template>
              </div>
            </div>
          </a-form-model-item>

          <a-form-model-item>
            <div class="form-item">
              <div>递增额度</div>
              <div>
                <template>{{ MoneyMangeInfo.incremental_amount }}</template>
              </div>
            </div>
          </a-form-model-item>

          <a-form-model-item>
            <div class="form-item">
              <div>开始日期</div>
              <div>
                <template>{{ MoneyMangeInfo.start_date }}</template>
              </div>
            </div>
          </a-form-model-item>

          <a-form-model-item>
            <div class="form-item">
              <div>起息天数</div>
              <div>
                <template>{{ MoneyMangeInfo.delay }}</template>
              </div>
            </div>
          </a-form-model-item>

          <a-form-model-item>
            <div class="form-item">
              <div>基金周期</div>
              <div>
                <template>{{ MoneyMangeInfo.cycle }}</template>
              </div>
            </div>
          </a-form-model-item>

          <a-form-model-item>
            <div class="form-item">
              <div>收益模式</div>
              <div>
                <template>{{ MoneyMangeInfo.mode }}</template>
              </div>
            </div>
          </a-form-model-item>
        </template>
        <a-form-model-item prop="number">
          <div class="form-item">
            <div>购买份数</div>
            <div class="number">
              <a-button @click="numberMinusPlus('-')">
                <a-icon type="minus" />
              </a-button>
              <a-input
                v-model="MoneyMangeForm.item.number"
                type="number"
                autocomplete="off"
                onkeyup="value=value.replace(/^(0+)|[^\d]+/g,'')"
              />
              <a-button @click="numberMinusPlus('+')">
                <a-icon type="plus" />
              </a-button>
            </div>
          </div>
        </a-form-model-item>
        <a-form-model-item>
          <div class="form-item">
            <div>总计</div>
            <template>
              <div class="feature">
                <!-- parseInt(MoneyMangeInfo.price) * -->
                {{
                  MoneyMangeInfo.Amount *
                  parseInt(MoneyMangeForm.item.number)
                }}
                USDT
              </div>
            </template>
          </div>
        </a-form-model-item>
        <a-form-model-item :style="{ textAlign: 'right' }">
          <span @click="agreementRead">
            <a-checkbox disabled v-model="MoneyMangeForm.item.agreement">
              <span>
                <span>我已阅读</span>
                <span :style="{ color: '#1890ff', fontSize: '1.2em' }">
                  《购买协议》
                </span>
              </span>
            </a-checkbox>
            <a-modal
              v-model="agreementShow"
              id="agreement-content"
              title="购买协议"
              :centered="true"
              :destroyOnClose="true"
              @ok="agreementConsent"
              @cancel="agreementCancel"
            >
              <div slot="footer">
                <a-button id="consent-agreement" @click="agreementConsent">
                  <span>同意《购买协议》</span>
                  <span class="countDown" v-if="readCountDown.show">
                    ( <b>{{ readCountDown.count }}</b> )
                  </span>
                  <span class="scroll" v-if="readScrollDown.show">
                    ( <b>{{ readScrollDown.text }}</b> )
                  </span>
                </a-button>
              </div>
              <div id="agreement-body">
                <div id="agreement-body-content">
                  <money-manage-agreement />
                </div>
              </div>
            </a-modal>
          </span>
        </a-form-model-item>
        <a-form-model-item v-show="false">
          <a-modal
            v-model="passwordEnterShow"
            id="password-enter"
            :centered="true"
            :closable="false"
            :destroyOnClose="true"
          >
            <div slot="footer" style="display: flex;">
              <a-button @click="passwordEnterShow = false" type="primary">
                取消
              </a-button>
              <a-button @click="(e) => passwordEnter('MoneyMangeForm', e)">
                确认
              </a-button>
            </div>
            <a-input-password
              v-model="MoneyMangeForm.item.password"
              placeholder="请输入您的交易密码"
            >
              <a-icon slot="prefix" type="lock" />
            </a-input-password>
          </a-modal>
        </a-form-model-item>
        <a-form-model-item>
          <a-button
            id="submit-button"
            html-type="submit"
            :class="!MoneyMangeForm.item.agreement ? 'disabled' : ''"
            @click="handleSubmitPurchase"
          >
            立即购买
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Radio, Collapse, FormModel } from "ant-design-vue";
Vue.use(Radio);
Vue.use(Collapse);
Vue.use(FormModel);

// TODO 协议模板
import MoneyManageAgreement from "@/components/agreement/financial-agreement";

export default {
  name: "Money-Manage-Details",
  components: {
    // TODO 挂载 协议模板
    MoneyManageAgreement,
  },
  // 数据
  data() {
    return {
      MoneyMangeForm: {
        item: {
          number: 1,
          agreement: false,
          password: "",
          payment_method: 0,
        },
      },
      agreementError: "",
      agreementShow: false,
      consentAgreementDisabled: true,
      readScrollDown: {
        show: true,
        text: "请仔细阅读协议",
      },
      readCountDown: {
        count: 5,
        show: true,
        intervalID: null,
      },
      passwordEnterShow: false,
      MoneyMangeInfo: {},
      activeKey: 0,
    };
  },
  filters: {
    toNumber(v) {
      return (v + '').replace(/(\.\d{0,2})\d+/, '')
    }
  },
  computed: {
    IncrementalAmount() {
      const a = this.MoneyMangeForm?.item?.incremental_amount
      try {
        return (a.split(' ')[0]).replace(',', '') || 0
      } catch (e) {
        return 0
      }
    }
  },
  // 方法
  methods: {
    async getMoneyManageDetails() {
      await this.$axios
        .get("financial?id=" + this.$route.params.id)
        .then((res) => {
          if (res.code === 0) {
            this.MoneyMangeInfo = res.data;
          }
        });
    },
    handleSubmitPurchase(e) {
      e.stopPropagation();
      e.preventDefault();
      // console.log("asd");
      this.passwordEnterShow = this.MoneyMangeForm.item.agreement;
      if (!this.MoneyMangeForm.item.agreement) {
        this.$message.warning("请仔细阅读《购买协议》");
        return false;
      }
    },
    passwordEnter(formName, e) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const datas = this.MoneyMangeForm.item;

          this.$(e.toElement).css("pointer-events", "none");
          this.$(e.toElement).attr("disabled", true);
          this.$message.warning("购买中。。。");

          this.$axios
            .post(
              'financial-purchase',
              this.$axios.qs.stringify({
                // ...datas
                id: this.$route.params.id,
                quantity: datas.number,
                pay_pwd: this.$axios.Base64.encode(datas.password),
                // payment_method: datas.payment_method,
              })
            )
            .then((res) => {
              console.log('@@ res', res)
              setTimeout(() => {
                this.$(e.toElement).css("pointer-events", "auto");
                this.$(e.toElement).attr("disabled", false);
              }, 550);
              if (res.code === 0) {
                // this.$message.success(res.message);
                this.$message.success("购买成功");
                setTimeout(() => {
                  this.$router.push({ name: "My-Momey-Manage" })
                }, 500);
              }
            });
        }
      });
    },
    numberMinusPlus(symbol) {
      this.MoneyMangeForm.item.number = parseInt(this.MoneyMangeForm.item.number);
      if (this.MoneyMangeForm.item.number <= 1 && symbol === "-") return;
      if (99999 <= this.MoneyMangeForm.item.number && symbol === "+") return;
      this.MoneyMangeForm.item.number += parseInt(symbol + 1);
    },
    agreementRead() {
      this.agreementShow = true;
      setTimeout(() => {
        const domScroll = document.getElementById("agreement-body");
        const domContent = document.getElementById("agreement-body-content");
        domScroll.addEventListener("scroll", () => {
          // 判断协议是否浏览到底部
          // +10是因为不同浏览器对于各种页面参数计算不一致，不加可能会导致页面滚到底部，但计算不识别
          if (
            parseInt(domContent.offsetHeight) <
            parseInt(domScroll.offsetHeight + domScroll.scrollTop + 10)
          ) {
            this.readScrollDown.show = false;
          }
          this.agreementButtonDisabled();
        });
        this.agreementButtonDisabled();
      }, 50);
      this.readCountDown.intervalID = setInterval(() => {
        if (1 < this.readCountDown.count) {
          this.readCountDown.count--;
        } else {
          clearInterval(this.readCountDown.intervalID);
          this.readCountDown.show = false;
        }
        this.agreementButtonDisabled();
      }, 1000);
    },
    agreementCancel() {
      // 判断协议是否已阅读，如果未阅读，清除阅读进度
      if (!this.MoneyMangeForm.item.agreement) {
        setTimeout(() => {
          if (this.readCountDown.intervalID)
            clearInterval(this.readCountDown.intervalID);
          this.readScrollDown.show = true;
          this.readCountDown = { count: 5, show: true, intervalID: null };
          this.consentAgreementDisabled = true;
        }, 50);
      }
    },
    agreementConsent() {
      if (!this.consentAgreementDisabled) {
        this.agreementShow = false;
        this.MoneyMangeForm.item.agreement = true;
      } else {
        this.$message.warning("请仔细阅读《购买协议》");
      }
    },
    agreementButtonDisabled() {
      // 判断协议内容是否滚动到底部 并且 是否已经等待指定时长
      this.consentAgreementDisabled = !(
        !this.readScrollDown.show & !this.readCountDown.show
      );
    },
  },
  watch: {
    "MoneyMangeForm.item.number"(newVal) {
      // console.log("监听:", newVal);
      // if (!newVal || NewsVue <= 0) this.MoneyMangeForm.item.number = 1;
      if (newVal < this.MoneyMangeForm.item.numberMin)
        this.MoneyMangeForm.item.number = this.MoneyMangeForm.item.numberMin;
      if (this.MoneyMangeForm.item.numberMax < newVal)
        this.MoneyMangeForm.item.number = this.MoneyMangeForm.item.numberMax;
      else if (99999 < newVal) {
        this.MoneyMangeForm.item.number = 99999;
      }
      this.MoneyMangeForm.item.number = parseInt(this.MoneyMangeForm.item.number);
    },
  },
  created() {
    this.getMoneyManageDetails()
  },
};
</script>

<!-- 添加"scoped"属性，以限制CSS仅对该组件 -->
<style lang="less">
#app {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  > div {
    width: 100%;
    flex-shrink: 1;
  }

  > div:not(#Header):not(#Footer) {
    height: 100%;
  }

  > #Header,
  > #Footer {
    flex-shrink: 0;
  }
}

#agreement-content .ant-modal {
  height: 95%;

  > .ant-modal-content {
    height: 100%;

    > .ant-modal-body {
      height: 100%;
      overflow: hidden;
      // border: 1px solid red;
      // background-color: red;

      #agreement-body {
        overflow: auto;
        overflow-x: hidden;
        height: 100%;
        // border: 1px solid red;
        // background-color: red;
      }
    }

    > .ant-modal-footer {
      #consent-agreement.ant-btn {
        width: 100%;
      }
    }
  }
}

#Money-Manage-Details {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-content: center;
  align-items: center;
  padding: 1em 0;
  padding-bottom: 0 !important;
  // height: 100%;

  > * {
    width: 100%;
  }

  > img {
    max-width: 480px;
    margin: 0 auto;
  }

  > .purchase-form {
    padding: 1.5em;
    border-radius: 1.5em;
    margin-top: 1em;
    // border-top-left-radius: 1.2em;
    // border-top-right-radius: 1.2em;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.2);
    background-color: #3d447b;

    > .ant-form {
      .form-item {
        display: flex;
        justify-content: space-between;
        align-content: center;
        align-items: center;

        > * {
          flex-shrink: 0;

          &:first-child {
            max-width: 40%;
          }

          &:last-child {
            max-width: 60%;
            text-align: right;
            line-height: 1.3em;
          }
        }

        .number {
          flex: 1 1 100%;
          display: flex;
          justify-content: center;
          align-content: stretch;
          align-items: stretch;
          //: 1em;

          > * {
            // height: 0.64rem;
            border-color: #1890ff !important;
            height: auto !important;

            &.ant-btn {
              flex: 0 0 auto;
              width: auto;

              > i {
                height: 100%;
                display: flex;
                align-content: center;
                align-items: center;
              }

              &:first-child {
                border-right: 0;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
              }

              &:last-child {
                border-left: 0;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
              }
            }

            &.ant-input {
              flex: 1 1 100%;
              width: 5em;
              text-align: center;
              border-radius: 0;
              border-width: 1px;
              color: black;
              border-color: white;
              background-color: white;
            }
          }
        }
      }
      .ant-checkbox.ant-checkbox-checked.ant-checkbox-disabled {
        > .ant-checkbox-inner {
          border-color: #1890ff;
          background-color: #1890ff;

          &::after {
            border-color: #f5f5f5;
          }
        }
      }

      .ant-collapse {
        border-bottom: 0;

        > * {
          border-bottom: 0;

          > * {
            padding-left: 0;
            padding-right: 0;
          }
        }

        .ant-collapse-content-box {
          padding: 0;
          background-color: #3d447b;

          > * {
            margin: 0;
            margin-bottom: 0.5em;
          }
        }

        .ant-btn {
          background-color: #3d447b;
        }

        .mode-of-payment-parent {
          display: flex;
          justify-content: space-between;
          margin-right: 0;
          margin-bottom: 0.5em;

          > span:last-child:not(:first-child) {
            width: 100%;
            padding-right: 0;
          }

          .mode-of-payment {
            width: 100%;
            display: inline-flex;
            justify-content: space-between;
          }
        }
      }
    }
  }

  #agreement-content {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    padding: 1rem 1em;
    width: 100%;
    height: 100%;
    background-color: rgba(61, 68, 123, 0.5);
    border: 1px solid #000;

    > div {
      width: 100%;
      height: 100%;
      background-color: white;
    }
  }
}

#password-enter .ant-modal {
  max-width: 360px;
}
</style>
